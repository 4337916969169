@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: center;
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.subtext{
  margin-top: 0;
}

.subtext2{
  margin-bottom: 0;
}

.wrapper{
  margin-bottom: 5pc;
}

.slotWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 10pc;
  cursor: pointer;

  &:hover{
    transform: scale(1.05);
  }
}


.slotWrapper > svg{
  margin: auto 0;
  transform: scale(1.6);
  margin-bottom: -15px;
}

.icon{
  width: auto;
  height: 4pc;
  object-fit: cover;
}
.button{
  max-width: 15pc;
  border-radius: 20px;
}

.carouselWrapper{
  margin-top: 5vh;
  margin-bottom: 8vh;
  margin-left: 9vw;
  margin-right: 9vw;

  @media only screen and (max-width: 1100px){
  /* margin-bottom: 40vh; */
  }
}

.narrowText{
  line-height: 20px;
}


.iconImage{
  width: 4pc;
  height: auto;
  margin: 0 auto;
}

.iconLabel{
  margin-top: 10px;
  line-height: 20px;
}

